import React from 'react';
import { Underline } from '@strikelabs/luna';

const HeroTitle = () => (
  <>
    Let&apos;s sell your home
    <br /> for{' '}
    <Underline
      weight="h1"
      lineMultiplier={0.3}
      active
      auto
      delay={1}
      duration={1}
    >
      free
    </Underline>
  </>
);

export default HeroTitle;
