import React from 'react';
import PropTypes from 'prop-types';

const EstasAward = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 95 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1139.000000, -698.000000)" fill="#FFFFFE">
          <g transform="translate(1139.000000, 698.000000)">
            <polyline points="0 40 16 40 16 33.5585819 7.53378984 33.5585819 7.53378984 29.2000913 15.553899 29.2000913 15.553899 23.119294 7.53378984 23.119294 7.53378984 19.3204504 16 19.3204504 16 13 0 13" />
            <path d="M24.8265724,19.9496606 C24.8265724,18.9098546 25.6867138,18.1517673 27.7222925,18.1517673 C29.3264607,18.1517673 30.7747674,18.4307507 32.2226275,18.9499699 L32.8483067,12.5187633 C31.2825456,12.1595494 29.3264607,12 27.9960551,12 C21.0300707,12 17,15.4344138 17,20.7870667 C17,30.013578 26.8608113,27.6964655 26.8608113,31.6113508 C26.8608113,32.8900249 25.7260141,33.529134 24.317901,33.529134 C22.0094529,33.529134 20.0131745,33.0103707 18.251805,32.2500041 L17.6256792,39.3207756 C19.5442501,39.7998795 21.5387421,40 24.1620394,40 C30.5005582,40 35,35.9652248 35,30.6535988 C35,22.0661967 24.8265724,23.3439591 24.8265724,19.9496606" />
            <polyline points="54 13 35 13 35 19.39953 40.8023925 19.39953 40.8023925 40 48.1971538 40 48.1971538 19.39953 54 19.39953" />
            <path d="M58.6151255,13 L50,40 L57.2056377,40 L58.5360261,35.5993643 L66.6814713,35.5993643 L68.0525266,40 L76,40 L66.9554142,13 L58.6151255,13 Z M66.4718804,26.7583776 L63.1282571,30.1738917 C63.0947405,30.2085855 63.0487109,30.2277584 63.0004469,30.2277584 C62.9521829,30.2277584 62.9066002,30.2085855 62.8726366,30.1738917 L59.5285665,26.7583776 C58.6374699,25.8472086 58.6374699,24.3658743 59.5285665,23.4547053 C60.4196631,22.5435363 61.8702647,22.5435363 62.7631488,23.4547053 L63.0004469,23.6971055 L63.2377449,23.4547053 C63.668099,23.0146417 64.2427982,22.7722416 64.8550361,22.7722416 C65.4668271,22.7722416 66.0415263,23.0146417 66.4718804,23.4547053 C67.3634239,24.3658743 67.3634239,25.8472086 66.4718804,26.7583776 L66.4718804,26.7583776 Z" />
            <path d="M95,30.6535988 C95,22.0661967 84.8265724,23.344415 84.8265724,19.9492047 C84.8265724,18.9098546 85.6880536,18.1513114 87.7227391,18.1513114 C89.3269073,18.1513114 90.7743208,18.4307507 92.2226275,18.949514 L92.8483067,12.5187633 C91.284332,12.1590935 89.3269073,12 87.9960551,12 C81.0309639,12 77,15.4344138 77,20.7870667 C77,30.0140338 86.8617045,27.6969213 86.8617045,31.6118067 C86.8617045,32.8895691 85.7269073,33.5295899 84.317901,33.5295899 C82.0094529,33.5295899 80.0136211,33.0103707 78.251805,32.2500041 L77.6261258,39.3207756 C79.5433569,39.8003354 81.5396353,40 84.1602531,40 C90.5005582,40 95,35.9652248 95,30.6535988" />
            <polyline points="39.9268458 8 41.062595 8 41.062595 1.09034118 43 1.09034118 43 0 38 0 38 1.09034118 39.9268458 1.09034118" />
            <polyline points="46.2187926 4.40845747 49.7916478 4.40845747 49.7916478 8 51 8 51 0 49.7916478 0 49.7916478 3.3056223 46.2187926 3.3056223 46.2187926 0 45 0 45 8 46.2187926 8" />
            <polyline points="56 6.89709858 53.2255125 6.89709858 53.2255125 4.40872229 55.8665148 4.40872229 55.8665148 3.35387758 53.2255125 3.35387758 53.2255125 1.07791194 56 1.07791194 56 0 52 0 52 8 56 8" />
            <polyline points="33.6454183 3 0 3.89862694 34 5" />
            <polyline points="61.3537055 3 61 5 95 3.89900759" />
          </g>
        </g>
      </g>
    </svg>
  );
};

EstasAward.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default EstasAward;
