import React from 'react';

import { Text, Box } from '@strikelabs/luna';

import { GoldAward, EstasAward, PropertyOmbudsman } from './SVGs';

import {
  Container,
  InnerTop,
  InnerBottom,
  HighlightSpan,
  Heading,
  LinkWrapper,
  FooterLink,
  Divider,
  SmallText,
  SvgWrapper,
  EstasWrapper,
  GoldAwardWrapper,
  OmbudsmanWrapper,
  FlexWrapper,
} from './style';

const Footer = () => {
  const getYear = () => {
    const date = new Date();
    return date.getFullYear();
  };

  return (
    <Container>
      <InnerTop>
        <Heading>
          <Text as="h2" color="#fff" ext>
            Strike <HighlightSpan>feel free</HighlightSpan>
          </Text>
        </Heading>
      </InnerTop>
      <InnerBottom>
        <Box mb={3}>
          <LinkWrapper>
            <FooterLink href="/legal/terms-of-use">Terms of use</FooterLink>
            <Divider />
            <FooterLink href="/legal/cookie-policy">Cookie policy</FooterLink>
            <Divider />
            <FooterLink href="/legal/privacy-policy">Privacy policy</FooterLink>
            <Divider />
            <FooterLink href="/legal/complaints-procedure">
              Complaints procedure
            </FooterLink>
            <Divider />
            <FooterLink href="/legal">Legal</FooterLink>
          </LinkWrapper>
          <Box mt={2}>
            <SmallText>Copyright &copy; Strike Limited {getYear()}</SmallText>
          </Box>
        </Box>
        <SvgWrapper>
          <FlexWrapper>
            <EstasWrapper>
              <EstasAward width="100%" height="100%" />
            </EstasWrapper>
            <GoldAwardWrapper>
              <GoldAward width="100%" height="100%" />
            </GoldAwardWrapper>
          </FlexWrapper>
          <OmbudsmanWrapper>
            <PropertyOmbudsman width="100%" height="100%" />
          </OmbudsmanWrapper>
        </SvgWrapper>
      </InnerBottom>
    </Container>
  );
};

export default Footer;
