import React from 'react';
import PropTypes from 'prop-types';

const GoldAward = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 115 56"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.69601 0.161621H9.12501V1.72738H3.66502V4.76904H8.80001V6.3348H3.66502V9.55615H9.12501V11.1283H1.69601V0.161621Z"
        fill="#FFFFFE"
      />
      <path
        d="M12.193 10.9294C11.503 10.7301 10.859 10.4011 10.295 9.96046L10.984 8.51021C11.497 8.89431 12.07 9.1934 12.681 9.39577C13.311 9.59422 13.97 9.6896 14.631 9.67812C15.253 9.7175 15.874 9.57938 16.418 9.28025C16.618 9.16664 16.783 9.00282 16.897 8.80552C17.011 8.60822 17.07 8.38451 17.068 8.15727C17.071 7.9607 17.018 7.76733 16.914 7.59935C16.811 7.43137 16.661 7.29559 16.483 7.20756C15.868 6.90205 15.211 6.68592 14.533 6.56586C13.45 6.38901 12.415 5.99598 11.491 5.41079C11.173 5.16952 10.92 4.85507 10.753 4.49489C10.587 4.13471 10.512 3.73975 10.536 3.34451C10.526 2.72817 10.712 2.1243 11.069 1.61832C11.449 1.09346 11.968 0.682596 12.57 0.43118C13.283 0.131394 14.052 -0.0151694 14.826 0.00124049C15.581 -0.00270665 16.332 0.114316 17.049 0.347756C17.685 0.553383 18.268 0.89273 18.758 1.3424L18.108 2.79264C17.186 2.0352 16.025 1.61979 14.826 1.61832C14.227 1.58633 13.634 1.74125 13.129 2.0611C12.926 2.18803 12.76 2.36458 12.646 2.57367C12.532 2.78276 12.475 3.01732 12.479 3.25467C12.471 3.45264 12.518 3.64902 12.616 3.82222C12.713 3.99542 12.857 4.13875 13.032 4.23647C13.626 4.54871 14.267 4.76533 14.93 4.87817C15.702 5.04811 16.459 5.2821 17.192 5.57764C17.712 5.78665 18.172 6.11952 18.531 6.5466C18.854 6.97602 19.019 7.50104 18.999 8.03535C19.013 8.64929 18.829 9.25172 18.472 9.75512C18.082 10.2699 17.549 10.6621 16.938 10.8845C16.188 11.1693 15.389 11.3067 14.585 11.2888C13.773 11.2938 12.965 11.1792 12.187 10.9487"
        fill="#FFFFFE"
      />
      <path
        d="M23.25 1.79154H19.629V0.161621H28.918V1.79154H25.278V11.1283H23.25V1.79154Z"
        fill="#FFFFFE"
      />
      <path
        d="M35.645 7.01508L33.598 2.31782L31.557 7.01508H35.645ZM36.295 8.55517H30.881L29.763 11.122H27.728L32.759 0.155273H34.423L39.461 11.122H37.453L36.295 8.55517Z"
        fill="#FFFFFE"
      />
      <path
        d="M41.872 1.79154H38.252V0.161621H47.534V1.79154H43.9V11.1283H41.872V1.79154Z"
        fill="#FFFFFE"
      />
      <path
        d="M48.925 0.161621H56.355V1.72738H50.894V4.76904H56.029V6.3348H50.894V9.55615H56.355V11.1283H48.925V0.161621Z"
        fill="#FFFFFE"
      />
      <path
        d="M69.199 7.01508L67.151 2.31782L65.11 7.01508H69.199ZM69.849 8.55517H64.421L63.297 11.122H61.269L66.293 0.155273H67.931L72.969 11.122H70.96L69.849 8.55517Z"
        fill="#FFFFFE"
      />
      <path
        d="M83.122 5.19259V10.461C82.536 10.7316 81.918 10.9278 81.282 11.0449C80.557 11.1886 79.819 11.2617 79.079 11.2631C78.042 11.2892 77.016 11.0551 76.095 10.5829C75.262 10.1527 74.581 9.48093 74.145 8.65778C73.683 7.71761 73.459 6.68006 73.495 5.63536C73.462 4.60118 73.685 3.57459 74.145 2.64503C74.572 1.82868 75.238 1.15794 76.056 0.71992C76.951 0.247786 77.954 0.0133838 78.968 0.0397174C79.712 0.0353583 80.452 0.150218 81.159 0.379819C81.79 0.577642 82.372 0.9033 82.868 1.33596L82.179 2.77979C81.72 2.41413 81.204 2.12379 80.652 1.9199C80.125 1.74128 79.571 1.65232 79.014 1.65681C78.531 1.62419 78.048 1.69827 77.598 1.87367C77.148 2.04908 76.744 2.32137 76.414 2.6707C75.828 3.56803 75.517 4.61286 75.517 5.68028C75.517 6.7477 75.828 7.79253 76.414 8.68986C76.759 9.04729 77.179 9.32478 77.646 9.50232C78.112 9.67986 78.613 9.75305 79.111 9.71659C79.839 9.71775 80.563 9.61838 81.263 9.4214V6.64925H78.864V5.19259H83.122Z"
        fill="#FFFFFE"
      />
      <path
        d="M85.384 0.161621H92.814V1.72738H87.353V4.76904H92.482V6.3348H87.353V9.55615H92.814V11.1283H85.384V0.161621Z"
        fill="#FFFFFE"
      />
      <path
        d="M104.033 0.161621V11.1283H102.538L96.545 3.44072V11.1283H94.673V0.161621H96.168L102.148 7.82997V0.161621H104.033Z"
        fill="#FFFFFE"
      />
      <path
        d="M109.057 1.79154H105.436V0.161621H114.725V1.79154H111.085V11.1283H109.057V1.79154Z"
        fill="#FFFFFE"
      />
      <path
        d="M8.917 23.7058L6.87 19.0085L4.829 23.7058H8.917ZM9.56699 25.2458H4.159L3.035 27.8126H1L6.031 16.8459H7.669L12.707 27.8126H10.698L9.56699 25.2458Z"
        fill="#FFFFFE"
      />
      <path
        d="M28.768 16.8524L24.836 27.8191H23.198L20.403 19.9326L17.575 27.8191H15.937L12.024 16.8524H14.065L16.847 25.0341L19.733 16.8524H21.17L23.991 25.0919L26.831 16.8524H28.768Z"
        fill="#FFFFFE"
      />
      <path
        d="M35.977 23.7058L33.936 19.0085L31.888 23.7058H35.977ZM36.627 25.2459H31.219L30.094 27.8127H28.066L33.123 16.8524H34.761L39.805 27.8191H37.79L36.627 25.2459Z"
        fill="#FFFFFE"
      />
      <path
        d="M45.474 21.7229C46.066 21.767 46.656 21.6206 47.157 21.3058C47.346 21.1513 47.495 20.9538 47.59 20.7303C47.685 20.5068 47.724 20.2639 47.703 20.0224C47.722 19.7882 47.683 19.553 47.589 19.3372C47.495 19.1213 47.349 18.9314 47.164 18.7839C46.66 18.4699 46.067 18.3258 45.474 18.3732H42.939V21.7036L45.474 21.7229ZM50.141 27.8191H48.015L46.598 24.1678C46.505 23.8674 46.298 23.6144 46.02 23.4619C45.703 23.3164 45.355 23.2482 45.006 23.263H42.991V27.8191H40.995V16.8524H45.753C46.778 16.7809 47.796 17.0696 48.626 17.6673C48.959 17.9586 49.22 18.3209 49.39 18.7267C49.56 19.1325 49.635 19.5712 49.608 20.0095C49.629 20.675 49.423 21.3281 49.023 21.8641C48.6 22.3941 48.017 22.7765 47.359 22.955C47.697 23.0349 48.007 23.2026 48.257 23.4407C48.507 23.6787 48.689 23.9785 48.782 24.3089L50.141 27.8191Z"
        fill="#FFFFFE"
      />
      <path
        d="M55.874 26.202C58.435 26.202 59.715 24.9079 59.715 22.3197C59.715 19.7315 58.435 18.4481 55.874 18.4695H53.924V26.202H55.874ZM51.909 16.8524H55.997C56.764 16.8044 57.533 16.9061 58.26 17.1516C58.988 17.3971 59.659 17.7817 60.235 18.2834C61.209 19.4136 61.743 20.8489 61.743 22.3325C61.743 23.8162 61.209 25.2515 60.235 26.3817C59.659 26.8845 58.988 27.2702 58.261 27.5169C57.534 27.7635 56.765 27.8662 55.997 27.8191H51.909V16.8524Z"
        fill="#FFFFFE"
      />
      <path
        d="M65.188 27.6522C64.497 27.4568 63.851 27.1274 63.29 26.6832L63.979 25.2329C64.488 25.6204 65.059 25.9199 65.669 26.1185C66.299 26.318 66.958 26.4155 67.619 26.4072C68.242 26.4422 68.862 26.302 69.407 26.003C69.606 25.8894 69.771 25.7255 69.885 25.5282C70 25.3309 70.059 25.1072 70.057 24.88C70.059 24.6834 70.006 24.4901 69.903 24.3221C69.799 24.1541 69.65 24.0183 69.472 23.9303C68.855 23.6276 68.199 23.4116 67.522 23.2886C66.435 23.1161 65.397 22.7228 64.473 22.1335C64.157 21.8908 63.906 21.5759 63.74 21.216C63.575 20.856 63.501 20.4618 63.524 20.0672C63.515 19.4519 63.699 18.8489 64.051 18.341C64.435 17.8173 64.956 17.4069 65.559 17.1539C66.272 16.8573 67.04 16.7108 67.814 16.724C68.569 16.72 69.32 16.837 70.037 17.0705C70.677 17.2656 71.266 17.5938 71.766 18.033L71.116 19.4833C70.204 18.7305 69.055 18.3152 67.866 18.309C67.266 18.2782 66.67 18.433 66.163 18.7517C65.962 18.8805 65.796 19.0574 65.683 19.2661C65.569 19.4747 65.511 19.7084 65.513 19.9453C65.507 20.1445 65.556 20.3415 65.655 20.5155C65.753 20.6895 65.897 20.834 66.072 20.9335C66.665 21.2425 67.303 21.4589 67.964 21.5752C68.737 21.7432 69.494 21.9772 70.226 22.2747C70.749 22.4841 71.212 22.8194 71.571 23.2501C71.894 23.677 72.059 24.2001 72.039 24.7324C72.054 25.3474 71.867 25.9508 71.506 26.4522C71.12 26.9683 70.589 27.3611 69.979 27.5816C69.229 27.8664 68.429 28.0037 67.626 27.9858C66.813 27.9914 66.005 27.8767 65.227 27.6457"
        fill="#FFFFFE"
      />
      <path
        d="M85.39 26.7602V27.819H78.24V26.8629L82.14 22.6148C82.585 22.1431 82.969 21.6194 83.284 21.0554C83.512 20.6274 83.634 20.1527 83.642 19.6694C83.659 19.4131 83.621 19.1561 83.529 18.9158C83.437 18.6756 83.294 18.4576 83.109 18.2769C82.687 17.9266 82.144 17.7518 81.594 17.7892C80.423 17.8373 79.307 18.2918 78.442 19.0726L77.961 18.1036C78.437 17.6667 78.999 17.3304 79.612 17.1154C80.256 16.876 80.938 16.7521 81.627 16.7496C82.503 16.7004 83.366 16.9775 84.045 17.5261C84.341 17.7895 84.574 18.1142 84.728 18.4769C84.882 18.8397 84.953 19.2315 84.935 19.6245C84.935 20.2542 84.788 20.8755 84.506 21.4405C84.124 22.1436 83.645 22.7913 83.083 23.3656L79.924 26.7602H85.39Z"
        fill="#FFFFFE"
      />
      <path
        d="M92.956 25.772C93.452 24.6898 93.675 23.5054 93.606 22.3197C93.674 21.1444 93.451 19.9706 92.956 18.8994C92.751 18.5717 92.463 18.3014 92.122 18.1139C91.781 17.9264 91.397 17.828 91.006 17.828C90.616 17.828 90.232 17.9264 89.891 18.1139C89.549 18.3014 89.262 18.5717 89.056 18.8994C88.559 19.9695 88.335 21.1442 88.406 22.3197C88.337 23.5034 88.56 24.686 89.056 25.7656C89.257 26.0994 89.543 26.3758 89.885 26.5679C90.226 26.7599 90.613 26.8608 91.006 26.8608C91.4 26.8608 91.786 26.7599 92.128 26.5679C92.47 26.3758 92.755 26.0994 92.956 25.7656V25.772ZM88.101 26.51C87.438 25.5603 87.106 24.1678 87.106 22.3325C87.106 20.4972 87.438 19.1026 88.101 18.1486C88.439 17.7061 88.876 17.3471 89.379 17.0999C89.881 16.8527 90.435 16.724 90.997 16.724C91.558 16.724 92.112 16.8527 92.614 17.0999C93.117 17.3471 93.554 17.7061 93.892 18.1486C94.631 19.4101 94.975 20.8592 94.88 22.3132C94.975 23.7755 94.631 25.2328 93.892 26.5036C93.556 26.9484 93.119 27.3096 92.616 27.5584C92.114 27.8072 91.559 27.9367 90.997 27.9367C90.434 27.9367 89.88 27.8072 89.377 27.5584C88.874 27.3096 88.437 26.9484 88.101 26.5036"
        fill="#FFFFFE"
      />
      <path
        d="M104.123 26.7602V27.819H96.973V26.8629L100.873 22.6148C101.316 22.1432 101.698 21.6195 102.011 21.0555C102.241 20.6286 102.364 20.1531 102.368 19.6694C102.386 19.4137 102.347 19.1574 102.257 18.9173C102.166 18.6773 102.025 18.459 101.842 18.2769C101.419 17.9285 100.877 17.7538 100.327 17.7892C99.156 17.8357 98.039 18.2904 97.175 19.0726L96.687 18.1037C97.165 17.6653 97.729 17.3289 98.345 17.1154C98.987 16.8754 99.667 16.7515 100.353 16.7497C101.231 16.7019 102.096 16.9788 102.778 17.5261C103.073 17.7901 103.305 18.1151 103.457 18.4778C103.61 18.8405 103.68 19.2321 103.662 19.6245C103.661 20.2543 103.515 20.8755 103.233 21.4405C102.85 22.1436 102.371 22.7914 101.809 23.3656L98.637 26.7602H104.123Z"
        fill="#FFFFFE"
      />
      <path
        d="M113.236 26.7603V27.8191H107.042V26.7603H109.492V18.4246L107.211 19.8556V18.6363L110.051 16.8524H110.786V26.7603H113.236Z"
        fill="#FFFFFE"
      />
      <path
        d="M20.415 44.9134V49.0544C19.955 49.2694 19.469 49.4237 18.969 49.5134C18.399 49.6291 17.819 49.6865 17.237 49.6849C16.423 49.7061 15.618 49.5221 14.897 49.1502C14.242 48.8113 13.707 48.2835 13.364 47.6371C13 46.8981 12.825 46.0826 12.853 45.2614C12.827 44.4502 13.002 43.6449 13.364 42.9161C13.701 42.275 14.224 41.748 14.866 41.4029C15.569 41.0318 16.358 40.8476 17.155 40.8683C17.74 40.8648 18.321 40.9551 18.877 41.1356C19.373 41.29 19.831 41.5461 20.22 41.8871L19.71 43.027C19.348 42.7362 18.941 42.5062 18.504 42.3461C18.09 42.2057 17.654 42.1358 17.216 42.1393C16.837 42.1143 16.456 42.1733 16.103 42.3121C15.749 42.4508 15.431 42.6658 15.173 42.9413C14.716 43.6469 14.473 44.4669 14.473 45.3043C14.473 46.1417 14.716 46.9617 15.173 47.6673C15.444 47.9483 15.774 48.1664 16.141 48.3059C16.508 48.4455 16.901 48.503 17.293 48.4744C17.866 48.4744 18.437 48.398 18.989 48.2474V46.0634H17.094V44.9134H20.415Z"
        fill="#FFFFFE"
      />
      <path
        d="M27.951 47.6118C28.443 46.9228 28.681 46.0877 28.625 45.2462C28.683 44.4093 28.444 43.5785 27.951 42.8958C27.711 42.6358 27.419 42.428 27.094 42.2858C26.768 42.1436 26.416 42.0702 26.06 42.0702C25.704 42.0702 25.352 42.1436 25.027 42.2858C24.701 42.428 24.409 42.6358 24.17 42.8958C23.67 43.5762 23.426 44.4074 23.48 45.2462C23.424 46.0877 23.662 46.9228 24.155 47.6118C24.394 47.8718 24.686 48.0796 25.011 48.2218C25.337 48.364 25.689 48.4374 26.045 48.4374C26.401 48.4374 26.753 48.364 27.078 48.2218C27.404 48.0796 27.696 47.8718 27.935 47.6118H27.951ZM23.848 49.1249C23.226 48.7719 22.723 48.2456 22.402 47.6118C22.067 46.8732 21.894 46.0731 21.894 45.2639C21.894 44.4547 22.067 43.6545 22.402 42.916C22.716 42.2791 23.218 41.7515 23.843 41.4028C24.527 41.0514 25.287 40.8679 26.058 40.8679C26.829 40.8679 27.588 41.0514 28.272 41.4028C28.894 41.7518 29.393 42.2797 29.703 42.916C30.035 43.6554 30.206 44.4552 30.206 45.2639C30.206 46.0726 30.035 46.8724 29.703 47.6118C29.389 48.2466 28.889 48.7737 28.267 49.1249C27.588 49.4792 26.831 49.6643 26.063 49.6643C25.294 49.6643 24.538 49.4792 23.858 49.1249"
        fill="#FFFFFE"
      />
      <path
        d="M31.828 40.949H33.412V48.2877H37.494V49.5739H31.828V40.949Z"
        fill="#FFFFFE"
      />
      <path
        d="M41.812 48.2978C43.824 48.2978 44.831 47.289 44.831 45.2462C44.831 43.2035 43.809 42.22 41.812 42.22H40.279V48.2978H41.812ZM38.695 40.9489H41.909C42.511 40.9112 43.116 40.9911 43.687 41.1841C44.259 41.3771 44.787 41.6794 45.24 42.0737C46.005 42.962 46.425 44.0902 46.425 45.2563C46.425 46.4225 46.005 47.5506 45.24 48.439C44.787 48.8351 44.26 49.1392 43.689 49.3339C43.117 49.5287 42.512 49.6102 41.909 49.5738H38.695V40.9489Z"
        fill="#FFFFFE"
      />
      <path
        d="M63.918 40.949L60.827 49.5739H59.54L57.343 43.37L55.121 49.5739H53.833L50.757 40.949H52.362L54.548 47.3799L56.817 40.949H57.946L60.163 47.4303L62.396 40.949H63.918Z"
        fill="#FFFFFE"
      />
      <path d="M66.728 40.949H65.145V49.5739H66.728V40.949Z" fill="#FFFFFE" />
      <path
        d="M75.976 40.949V49.5739H74.801L70.085 43.5264V49.5739H68.614V40.949H69.794L74.494 46.9814V40.949H75.976Z"
        fill="#FFFFFE"
      />
      <path
        d="M85.239 40.949V49.5739H84.059L79.348 43.5264V49.5739H77.877V40.949H79.057L83.752 46.9814V40.949H85.239Z"
        fill="#FFFFFE"
      />
      <path
        d="M87.139 40.949H92.979V42.1847H88.687V44.5704H92.724V45.8062H88.687V48.3382H92.979V49.5739H87.139V40.949Z"
        fill="#FFFFFE"
      />
      <path
        d="M97.96 44.7771C98.426 44.8117 98.89 44.6967 99.284 44.4492C99.433 44.3281 99.55 44.1729 99.624 43.9972C99.699 43.8214 99.729 43.6304 99.713 43.4405C99.727 43.2565 99.696 43.0717 99.622 42.9022C99.548 42.7326 99.434 42.5833 99.289 42.467C98.893 42.2203 98.427 42.107 97.96 42.1442H95.983V44.762L97.96 44.7771ZM101.629 49.5738H99.958L98.844 46.6988C98.77 46.4634 98.607 46.265 98.389 46.144C98.14 46.0296 97.867 45.976 97.592 45.9876H96.029V49.5738H94.461V40.9488H98.2C99.006 40.8926 99.806 41.1195 100.459 41.5894C100.72 41.8184 100.926 42.1031 101.059 42.4221C101.193 42.741 101.251 43.0859 101.23 43.4304C101.247 43.9534 101.085 44.4668 100.77 44.8881C100.44 45.3063 99.981 45.6072 99.462 45.7455C99.728 45.8084 99.972 45.9402 100.169 46.1273C100.365 46.3144 100.508 46.55 100.581 46.8098L101.629 49.5738Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 38C0.25 36.4812 1.48122 35.25 3 35.25H111C112.519 35.25 113.75 36.4812 113.75 38V53C113.75 54.5188 112.519 55.75 111 55.75H3C1.48122 55.75 0.25 54.5188 0.25 53V38ZM3 36.75C2.30964 36.75 1.75 37.3096 1.75 38V53C1.75 53.6904 2.30964 54.25 3 54.25H111C111.69 54.25 112.25 53.6904 112.25 53V38C112.25 37.3096 111.69 36.75 111 36.75H3Z"
        fill="#FFFFFE"
      />
    </svg>
  );
};

GoldAward.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default GoldAward;
