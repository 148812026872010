import styled from 'styled-components';
import { rem } from 'polished';

export const Container = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.xs};

  ${({ theme }) => theme.media.smallTablet} {
    align-items: center;
    flex-direction: row;
  }

  ${({ theme }) => theme.media.tablet} {
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 0;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.sm};

  ${({ theme }) => theme.media.smallTablet} {
    margin-top: 0;
  }
`;

export const InputUnderline = styled.div`
  transition: all 0.2s ease-in-out;

  &:after {
    content: '';
    position: absolute;
    left: 15px;
    width: ${rem(140)};
    transform: translateY(-1px);
    height: ${rem(1)};
    background-color: ${({ theme, focused }) =>
      focused ? theme.colors.statusFocus : theme.colors.border};

    ${({ theme }) => theme.media.tablet} {
      width: ${rem(200)};
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  transform: translateY(-3px);
  margin-left: ${rem(-13)};

  ${({ theme }) => theme.media.smallTablet} {
    margin-left: 0;
    transform: translateY(-10px);
  }

  input {
    border: 0;

    &:focus {
      border: 0;
    }
  }
`;
