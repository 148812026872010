import styled from 'styled-components';
import { rem } from 'polished';

export const HeaderContainer = styled.div`
  background: #fff;
  position: fixed;
  width: 100%;
  left: 0;
  top: 83px;
  transition: all 0.2s ease-in-out;
  ${({ boxShadow }) =>
    boxShadow ? 'box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);' : ''}
  ${({ show }) =>
    !show ? 'transform: translate(0, -100%); box-shadow: none;' : ''}
  z-index: 9999;
  height: 83px;
`;

export const HeaderInner = styled.div`
  position: relative;
  padding: 0 ${({ theme }) => `${theme.defaults.gutterSpacing}`};
  max-width: 100%;
  margin: 0 auto;
  height: ${rem(83)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.media.desktop} {
    padding: 0 ${rem(64)};
  }
`;

export const LogoContainer = styled.div`
  ${({ theme }) => theme.media.desktop} {
    max-width: 50%;
  }
  svg {
    display: block;
  }
`;

export const NavLink = styled.a`
  text-decoration: none;
`;

export const ShowBrandDesktop = styled.div`
  display: none;
  ${({ theme }) => theme.media.desktop} {
    display: block;
  }
`;

export const ShowBrandMobile = styled.div`
  display: block;
  ${({ theme }) => theme.media.desktop} {
    display: none;
  }
`;
