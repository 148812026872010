import styled from 'styled-components';
import { rem } from 'polished';

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InnerTop = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.footerWidth};
  display: flex;
  padding: ${({ theme }) =>
    `${theme.spacing.xl} ${theme.defaults.gutterSpacing} ${theme.spacing.sm}`};
  flex-direction: column;

  ${({ theme }) => theme.media.giant} {
    padding: ${({ theme }) =>
      `${theme.spacing.xxl} ${theme.defaults.gutterSpacing} ${theme.spacing.sm}`};
  }
`;

export const InnerBottom = styled(InnerTop)`
  flex-direction: column-reverse;
  padding: ${({ theme }) =>
    `${theme.spacing.sm} ${theme.defaults.gutterSpacing} ${theme.spacing.xl}`};

  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
    justify-content: space-between;
    padding: ${({ theme }) =>
      `${theme.spacing.sm} ${theme.defaults.gutterSpacing} ${theme.spacing.xl}`};
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
`;

export const SocialLinks = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing.lg};

  ${({ theme }) => theme.media.giant} {
    justify-content: flex-end;
  }
`;

export const SocialLink = styled.a`
  padding: 0 ${({ theme }) => theme.spacing.xs};
`;

export const Divider = styled.span`
  &:after {
    content: '|';
    color: #fff;
    font-weight: 100;
    padding: 0 ${rem(5)};
  }
`;

export const FooterLink = styled.a`
  color: #fff;
  font-size: ${rem(12)};
  text-decoration: none;
  font-weight: 100;
`;

export const SmallText = styled.p`
  font-size: ${rem(12)};
  width: 100%;
  color: #fff;
  font-weight: 100;
  line-height: ${rem(15)};
`;

export const SvgWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  width: 100%;

  ${({ theme }) => theme.media.smallTablet} {
    width: auto;
  }

  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 0;
    margin-left: ${({ theme }) => theme.spacing.md};
  }
`;

export const Heading = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

export const HighlightSpan = styled.span`
  color: ${({ theme }) => theme.colors.bodyText};
  background-color: ${({ theme }) => theme.colors.accent};
`;

export const EstasWrapper = styled.div`
  width: ${rem(85)};
  height: ${rem(30)};

  ${({ theme }) => theme.media.smallTablet} {
    width: ${rem(95)};
    height: ${rem(40)};
  }
`;

export const GoldAwardWrapper = styled.div`
  width: ${rem(78)};
  height: ${rem(38)};
  margin-top: ${({ theme }) => theme.spacing.xs};

  ${({ theme }) => theme.media.smallTablet} {
    margin-top: 0;
    margin-left: ${({ theme }) => theme.spacing.sm};
    width: ${rem(81)};
    height: ${rem(43)};
  }
`;

export const OmbudsmanWrapper = styled.div`
  width: ${rem(124)};
  height: ${rem(36)};
  margin-left: ${({ theme }) => theme.spacing.rg};

  ${({ theme }) => theme.media.smallTablet} {
    width: ${rem(155)};
    height: ${rem(45)};
  }

  ${({ theme }) => theme.media.desktop} {
    margin-left: ${({ theme }) => theme.spacing.lg};
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.media.smallTablet} {
    align-items: flex-start;
    flex-direction: row;
  }
`;
