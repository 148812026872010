import styled from 'styled-components';
import { Flex } from '@strikelabs/luna';
import { rem } from 'polished';

export const Container = styled(Flex)`
  margin-top: ${({ theme }) => theme.spacing.rg};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  flex-wrap: wrap-reverse;
  background: #1b2021;
  justify-content: center;

  ${({ theme }) => theme.media.tablet} {
    height: ${rem(100)};
    margin-top: ${({ theme }) => theme.spacing.xl};
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    align-items: center;
    padding: ${({ theme }) => `0 ${theme.spacing.lg}`};
  }

  ${({ theme }) => theme.media.desktop} {
    padding: ${({ theme }) => `0 ${theme.spacing.xl}`};
  }
`;
