import styled from 'styled-components';
import { Text } from '@strikelabs/luna';
import { rem } from 'polished';

import { Container } from 'components/common';

export const IntroContainer = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.md};

  ${({ theme }) => theme.media.smallTablet} {
    margin-top: ${({ theme }) => theme.spacing.lg};
    margin-bottom: ${({ theme }) => theme.spacing.lg};

  ${({ theme }) => theme.media.desktop} {
    margin-top: ${({ theme }) => theme.spacing.xxl};
  margin-bottom: ${({ theme }) => theme.spacing.xxl};
  }
`;

export const Headline = styled(Text).attrs({
  as: 'h2',
  ext: true,
})`
  font-weight: 500;
  font-size: ${rem(20)};
  line-height: ${rem(30)};

  ${({ theme }) => theme.media.smallTablet} {
    font-size: ${rem(22)};
    line-height: ${rem(32)};
  }
  ${({ theme }) => theme.media.desktop} {
    font-size: ${rem(30)};
    line-height: ${rem(40)};
  }
`;
