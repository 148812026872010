import React from 'react';
import { Text } from '@strikelabs/luna';

import { Container } from 'components/common';

const Disclaimer = () => (
  <Container>
    <Text as="p" ext mb={[4, 4, 4, 4, '0px']}>
      *All claims substantiated by TwentyCI, for all the detail please visit{' '}
      <a href="/more">strike.co.uk/more</a>
    </Text>
  </Container>
);

export default Disclaimer;
