import styled from 'styled-components';

export const CTAContainer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  padding: ${({ theme }) => `${theme.spacing.sm}`} 0;
  flex-direction: column-reverse;
  background: rgba(255, 255, 255, 1);
  justify-content: center;
  align-items: center;
  border-top: solid 1px #c9c7c7;

  ${({ theme }) => theme.media.tablet} {
    padding: ${({ theme }) => `${theme.spacing.xs}`}
      ${({ theme }) => `${theme.spacing.xxl}`};
    flex-direction: row;
  }
`;
