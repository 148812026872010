import React from 'react';
import { Flex, Icon, Box, useIsMedia } from '@strikelabs/luna';

import TrustBox from 'components/TrustBox';
import ZooplaLogo from './Logos/zoopla-logo.png';
import { Container } from './style.js';

const Portals = () => {
  const { isMobile } = useIsMedia();
  return (
    <Container>
      <Flex
        width={[1 / 2, 1 / 2, 1 / 3, 1 / 3]}
        alignItems="center"
        justifyContent={['flex-end', 'flex-end', 'flex-end', 'center']}
        pr={[1, 1, 1, '-0']}
      >
        <img
          src={ZooplaLogo}
          style={{ width: `${isMobile ? '76px' : '171px'}` }}
          alt="Zoopla logo"
        />
      </Flex>
      <Flex
        width={[1 / 2, 1 / 2, 1 / 3, 1 / 3]}
        alignItems="center"
        justifyContent={['flex-start', 'flex-start', 'flex-start', 'center']}
        pl={[1, 1, 1, '-0']}
      >
        <Icon
          type="rightmove"
          width={isMobile ? '100px' : '240px'}
          theme="dark"
        />
      </Flex>
      <Flex
        width={[1 / 2, 1 / 2, 1 / 3, 1 / 3]}
        alignItems="center"
        justifyContent="center"
        mb={[1, 1, '-0', '-0']}
      >
        <Box px={[null, null, 4, 2]}>
          <TrustBox theme="dark" scale={isMobile ? 1 : 1.5} />
        </Box>
      </Flex>
    </Container>
  );
};

export default Portals;
