import styled from 'styled-components';
import { rem } from 'polished';

import { Container } from 'components/common';

export const Outer = styled(Container)`
  margin-bottom: ${({ theme }) => theme.spacing.xl};

  ${({ theme }) => theme.media.desktop} {
    margin-bottom: ${({ theme, firstTable }) =>
      firstTable ? theme.spacing.xxl : theme.spacing.xl};
  }
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;

  ${({ theme }) => theme.media.smallTablet} {
    border-spacing: ${({ theme }) => `${theme.spacing.sm} 0`};
  }

  ${({ theme }) => theme.media.tablet} {
    border-spacing: ${({ theme }) => `${theme.spacing.md} 0`};
  }
`;

export const Th = styled.th`
  border-bottom: solid 1px ${({ theme }) => theme.colors.borderLight};
  padding-bottom: ${({ theme }) => theme.spacing.xs};
  width: 40%;

  ${({ theme }) => theme.media.desktop} {
    width: 50%;
  }

  ${({ theme }) => theme.media.giant} {
    width: 60%;
  }
`;

export const FirstTd = styled.td`
  text-align: ${({ service }) => (service ? 'left' : 'center')};
  padding: ${({ theme }) => `${theme.spacing.md} 0 ${theme.spacing.xs}`};
`;

export const Td = styled.td`
  text-align: ${({ service }) => (service ? 'left' : 'center')};
  padding: ${({ theme }) => `${theme.spacing.xs} 0`};
`;

export const LastTd = styled.td`
  text-align: ${({ service }) => (service ? 'left' : 'center')};
  padding: ${({ theme }) => `${theme.spacing.xs} 0 ${theme.spacing.md}`};
`;

export const Tf = styled.td`
  border-top: solid 1px ${({ theme }) => theme.colors.borderLight};
  padding-top: ${({ theme }) => theme.spacing.xs};
  text-align: center;
  font-size: ${({ large }) => (large ? rem(1.8) : rem(0.8))};
  font-weight: ${({ large }) => (large ? '600' : '500')};
`;

export const Breakpoint = styled.br`
  display: none;

  ${({ theme }) => theme.media.giant} {
    display: block;
  }
`;

export const Image = styled.img`
  width: 91px;

  ${({ theme }) => theme.media.desktop} {
    width: 152px;
  }
`;
